import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { pure } from 'recompose';
import { Link, browserHistory } from 'react-router';
import cx from 'classnames';
import styles from './NewsBox.scss';
import TaggedLinkList from './TaggedLinkList/TaggedLinkList';
import { Button } from 'sema-ui-components';

import { newsTypes } from 'constants/index';

export const NewsBox = ({ className, articles, locale, newsGroup }) => {
  const goToNews = () => {
    browserHistory.push(`/${locale}/ajankohtaista/uutiset`);
  };

  const goToEvents = () => {
    browserHistory.push(`${locale}/ajankohtaista/tapahtumat`);
  };

  const goToMalfunctions = () => {
    browserHistory.push(`${locale}/ajankohtaista/hairiotiedotteet`);
  };

  const goToReleases = () => {
    browserHistory.push(`${locale}/ajankohtaista/julkaisutiedotteet`);
  };

  const latestNews = () => {
    let filtered = articles.filter(article => {
      if (article.priority === 2) {
        let isNews = article.tags.find(tag => tag.slug === newsTypes.NEWS);
        if (isNews) {
          return article;
        }
      }
    });
    return filtered.slice(0, 2);
  };

  const events = () => {
    let filtered = articles.filter(article => {
      if (article.priority === 2) {
        let isEvent = article.tags.find(tag => tag.slug === newsTypes.EVENT);
        if (isEvent) {
          return article;
        }
      }
    });
    return filtered.slice(0, 2);
  };

  const malfunctionReports = () => {
    let filtered = articles.filter(article => {
      if (article.priority === 2) {
        let isMalfunction = article.tags.find(
          tag => tag.slug === newsTypes.MALFUNCTION
        );
        if (isMalfunction) {
          return article;
        }
      }
    });
    return filtered.slice(0, 2);
  };

  const releases = () => {
    let filtered = articles.filter(article => {
      if (article.priority === 2) {
        let isRelease = article.tags.find(
          tag => tag.slug === newsTypes.RELEASE
        );
        if (isRelease) {
          return article;
        }
      }
    });
    return filtered.slice(0, 2);
  };

  return (
    <div className={cx(styles.base, className)}>
      <div className="row">
        {newsGroup.indexOf(newsTypes.NEWS) >= 0 && latestNews().length > 0 && (
          <div className={cx('col-md-12 mb-m')}>
            <h3 className={styles.main_title}>
              <FormattedMessage id="topical.news.title" />
            </h3>
            <TaggedLinkList items={latestNews()} locale={locale} />
            <div className="v-offset-4">
              <Button
                small
                secondary
                onClick={goToNews}
                data-test="button-gotonews"
              >
                <FormattedMessage id="topical.all_news" />
              </Button>
            </div>
          </div>
        )}
        {newsGroup.indexOf(newsTypes.MALFUNCTION) >= 0 &&
          malfunctionReports().length > 0 && (
            <div className={cx('col-md-12 mb-m')}>
              <h3 className={styles.main_title}>
                <FormattedMessage id="topical.malfunctions.title" />
              </h3>
              <TaggedLinkList items={malfunctionReports()} locale={locale} />
              <div className="v-offset-4">
                <Button
                  small
                  secondary
                  onClick={goToMalfunctions}
                  data-test="button-gotomalfunctions"
                >
                  <FormattedMessage id="topical.all_malfunctions" />
                </Button>
              </div>
            </div>
          )}
        {newsGroup.indexOf(newsTypes.EVENT) >= 0 &&
          events().length > 0 &&
          malfunctionReports().length <= 0 && (
            <div className={cx('col-md-12 mb-m')}>
              <h3 className={styles.main_title}>
                <FormattedMessage id="topical.events.title" />
              </h3>
              <TaggedLinkList items={events()} locale={locale} />
              <div className="v-offset-4">
                <Button
                  small
                  secondary
                  onClick={goToEvents}
                  data-test="button-gotoevents"
                >
                  <FormattedMessage id="topical.all_events" />
                </Button>
              </div>
            </div>
          )}
        {newsGroup.indexOf(newsTypes.RELEASE) >= 0 && releases().length > 0 && (
          <div className={cx('col-md-12 mb-m')}>
            <h3 className={styles.main_title}>
              <FormattedMessage id="topical.releases.title" />
            </h3>
            <TaggedLinkList items={releases()} locale={locale} />
            <div className="v-offset-4">
              <Button
                small
                secondary
                onClick={goToReleases}
                data-test="button-gotoreleases"
              >
                <FormattedMessage id="topical.all_releases" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

NewsBox.propTypes = {
  articles: PropTypes.any,
  locale: PropTypes.string,
  className: PropTypes.string
};

export default pure(NewsBox);
