import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import TagsList from './TagsList';
import styles from './TaggedLinkList.scss';

const TaggedLinkList = ({ items, locale }) => (
  <ul className={styles.base}>
    {items &&
      items.map(x => (
        <li key={x.id}>
          <TagsList
            updateDate={x.updateDate}
            categories={x.tags}
            locale={locale}
          />
          <div className={styles.title}>
            <Link to={`/${locale}/ajankohtaista/uutiset/${x.id}`}>
              {x.title[locale]}
            </Link>
          </div>
        </li>
      ))}
  </ul>
);

TaggedLinkList.propTypes = {
  items: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired
};

export default TaggedLinkList;
