import {
  createAction as ca,
  createReducer,
  getSemaJSON as get
} from '../../../utils';
import { CALL_API } from '../../../store/middleware/api';

const initialState = {
  isFetchingDocuments: false,
  documentsByCategory: {},
  documents: [],
  selectedReport: null,
  selectedCategory: 'ptv',
  error: null
};

const PX = 'STATISTICS:';
const FETCH_DOCUMENTS = `${PX}_FETCH_DOCUMENTS`;
const FETCH_DOCUMENTS_SUCCESS = `${PX}_FETCH_DOCUMENTS_SUCCESS`;
const FETCH_DOCUMENTS_ERROR = `${PX}_FETCH_DOCUMENTS_ERROR`;
const UPDATE_VALUE = `${PX}_UPDATE_VALUE`;
const FETCH_DOCUMENTS_BY_CATEGORY = `${PX}_FETCH_DOCUMENTS_BY_CATEGORY`;
const FETCH_DOCUMENTS_BY_CATEGORY_SUCCESS = `${PX}_FETCH_DOCUMENTS_BY_CATEGORY_SUCCESS`;
const FETCH_DOCUMENTS_BY_CATEGORY_ERROR = `${PX}_FETCH_DOCUMENTS_BY_CATEGORY_ERROR`;
const FETCH_DOCUMENTS_BY_CATEGORY_WITH_TAGS_REQUEST = `${PX}_FETCH_DOCUMENTS_BY_CATEGORY_WITH_TAGS_REQUEST`;
const FETCH_DOCUMENTS_BY_CATEGORY_WITH_TAGS_SUCCESS = `${PX}_FETCH_DOCUMENTS_BY_CATEGORY_WITH_TAGS_SUCCESS`;
const FETCH_DOCUMENTS_BY_CATEGORY_WITH_TAGS_ERROR = `${PX}_FETCH_DOCUMENTS_BY_CATEGORY_WITH_TAGS_ERROR`;

const ACTION_HANDLERS = {
  [FETCH_DOCUMENTS]: state => ({
    ...state,
    isFetchingDocuments: true
  }),
  [FETCH_DOCUMENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isFetchingDocuments: false,
    documents: payload
  }),
  [FETCH_DOCUMENTS_ERROR]: (state, { payload }) => ({
    ...state,
    isFetchingDocuments: false,
    error: payload
  }),
  [UPDATE_VALUE]: (state, { payload }) => {
    return {
      ...state,
      ...payload
    };
  },
  [FETCH_DOCUMENTS_BY_CATEGORY]: state => ({
    ...state,
    isFetchingDocuments: true
  }),
  [FETCH_DOCUMENTS_BY_CATEGORY_SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      isFetchingDocuments: false,
      documentsByCategory: {
        ...state.documentsByCategory,
        [payload.category]: payload.documents
      }
    };
  },
  [FETCH_DOCUMENTS_BY_CATEGORY_ERROR]: (state, { payload }) => ({
    ...state,
    isFetchingDocuments: false
  }),
  [FETCH_DOCUMENTS_BY_CATEGORY_WITH_TAGS_REQUEST]: state => ({
    ...state,
    isFetchingDocuments: true
  }),
  [FETCH_DOCUMENTS_BY_CATEGORY_WITH_TAGS_SUCCESS]: (state, { payload }) => {

    return {
      ...state,
      isFetchingDocuments: false,
      documents: payload
    };
  },
  [FETCH_DOCUMENTS_BY_CATEGORY_WITH_TAGS_ERROR]: (state, { payload }) => ({
    ...state,
    isFetchingDocuments: false
  })
};

export const updateValue = (name, value) => {
  const valueMap = {};
  valueMap[name] = value;
  return dispatch => dispatch(ca(UPDATE_VALUE)(valueMap));
};

export const getDocuments = () => ({
  [CALL_API]: {
    method: 'get',
    endpoint: `/api/documents/`,
    types: [FETCH_DOCUMENTS, FETCH_DOCUMENTS_SUCCESS, FETCH_DOCUMENTS_ERROR]
  }
});

export const getDocumentsByCategory = category => async dispatch => {
  dispatch(ca(FETCH_DOCUMENTS_BY_CATEGORY)());
  try {
    const results = await get(`/api/documents/category/${category}`);
    dispatch(
      ca(FETCH_DOCUMENTS_BY_CATEGORY_SUCCESS)({ category, documents: results })
    );
    return { error: undefined };
  } catch (e) {
    console.error(`Error during fetching documents for ${category}:`, e);
    dispatch(ca(FETCH_DOCUMENTS_BY_CATEGORY_ERROR)(e));
    return { error: e || 'Unknown' };
  }
};

export const getDocumentsByCategoryWithTags = (category, tags)  => ({
    [CALL_API]: {
      method: 'get',
      endpoint: `/api/documents/category/${category}/tags/${tags}`,
      types: [FETCH_DOCUMENTS_BY_CATEGORY_WITH_TAGS_REQUEST, FETCH_DOCUMENTS_BY_CATEGORY_WITH_TAGS_SUCCESS, FETCH_DOCUMENTS_BY_CATEGORY_WITH_TAGS_ERROR]
    }
});

export default createReducer(ACTION_HANDLERS, initialState);
