import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Commons.scss';
import { FormattedDate } from 'react-intl';

export const TagsList = ({ updateDate, categories, locale, className }) => (
  <div className={cx(styles.briefCategory, className)}>
    {categories.map(article => (
      <div key={article.id} className={styles.briefCategoryInter}>
        {article.name[locale]} :
        <span className={styles.date}>
          <FormattedDate value={updateDate} />
        </span>
      </div>
    ))}
  </div>
);

TagsList.propTypes = {
  categories: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  className: PropTypes.string,
  updateDate: PropTypes.string.isRequired
};

export default TagsList;
